::-ms-clear {
    display: none;
  }
  
  ::-ms-reveal {
    display: none;
  }

  .ql-container {
    background-color: #f4f4f4;
    width: inherit
  }

  .ql-editor {
    color: #616161 !important;
    font-family: myriad-pro,roboto,Geneva,Tahoma,sans-serif !important;
    font-size: 16px !important;
}

.ql-tooltip input[type=text] {
  width: 400px !important;
}

.login-form .ql-editor ul {
  margin-top: 26px !important;
  padding-left: 17px !important;
}

.app-popup .ql-editor ul {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  padding-left: 40px !important;
}

.login-form .ql-editor li {
    margin-bottom: 26px !important;
    padding-left: 0px !important;
    list-style-type: disc !important;
}

.app-popup .ql-editor li {
  margin-bottom: 0px !important;
  padding-left: 0px !important;
  list-style-type: disc !important;
}

.ql-editor ul > li::before {
  content: none !important;
}
.ql-editor a {
    color: #e2000f !important
}

.ql-editor h1 {
  margin: 0px !important;
    line-height: 30px !important;
    font-weight: bold !important;
    font-size: 20px !important;
    color: #303030 !important
}
