//@import "bootstrap/bootstrap";

body {
    font-family: myriad-pro, Myriad Pro !important;
    margin: 0;
    font-size: 16px !important;
  }
  
  .h-100 {
    height: 100%;
  }
  
  .my-danfoss-maintenance-dashboard {   
    display: flex;
    flex-direction: column;
    background-color: transparent;
    height: 100%;
  }
  
  .flexgrow {
    display: flex;
    flex: 1 0 auto;
  }