.ql-container {
    min-height: 130px
}

.signupDescription ul {
    padding-left: 17px;
    margin-top: 26px;
}

.signupDescription li {
    margin-bottom: 26px
}

span ul {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    padding-left: 40px !important;
}

span li {
    margin-bottom: 0px !important;
    padding-left: 0px !important;
    list-style-type: disc !important;
}

a {
    cursor: pointer;
    color: #e2000f;
    font-size: 16px;
    letter-spacing: 0.4px;
}

h1 {
    font-size: 20px;
    margin: 0;
}